import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImg from "gatsby-background-image"
import Img from "gatsby-image"
import Title from "../components/banners/ServicesTitle"
import {
  FaBirthdayCake,
  FaGlassCheers,
  FaUserGraduate,
  FaHandshake,
  FaChild,
  FaUtensils,
} from "react-icons/fa"

import PageHero from "../components/banners/PageHero"

const BackgroundImageComponent = ({ children, imgSrc }) => {
  const ElementWrapper = styled.div`
    text-align: center;
    width: 100%;
    min-height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: calc(50vh - (var(--navbarheight) / 2));
    min-height: 400px;
    background: linear-gradient(
        100deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(100deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    text-shadow: 4px 4px 10px rgba(0, 0, 1, 0.9);
  `
  return (
    <BackgroundImg
      style={{ margin: "0", padding: "0" }}
      role="img"
      fluid={imgSrc}
    >
      <ElementWrapper>{children}</ElementWrapper>
    </BackgroundImg>
  )
}

const ServicesPage = ({ data, className }) => {
  const nastani = data.nastani.edges
  return (
    <>
      <PageHero title="Настани" imgSrc={data.allContentfulPageSettings.nodes[0].pageServicesImage.fluid} />
      <h2
        className="text-center"
        style={{
          background: "var(--maincolor)",
          margin: "0",
          color: "white",
          textTransform: "uppercase",
          textShadow: "4px 4px 6px rbga(0,0,0,0.5)",
          padding: "6px 0",
        }}
      >
        Најдоброто место за пријателите {"&"} фамилијата
      </h2>
      <section className={className}>
        {nastani.map(nastan => {
          const myIcon = nastan.node.icon
          return (
            <article className="services-card" key={nastan.node.title}>
              <BackgroundImageComponent imgSrc={nastan.node.sliki[0].fluid}>
                <Title
                  title={nastan.node.title}
                  text={nastan.node.text.text}
                  icon={
                    myIcon === "FaChild" ? (
                      <FaChild />
                    ) : myIcon === "FaHandshake" ? (
                      <FaHandshake />
                    ) : myIcon === "FaUserGraduate" ? (
                      <FaUserGraduate />
                    ) : myIcon === "FaBirthdayCake" ? (
                      <FaBirthdayCake />
                    ) : myIcon === "FaGlassCheers" ? (
                      <FaGlassCheers />
                    ) : (
                      <FaUtensils />
                    )
                  }
                />
              </BackgroundImageComponent>
              <div className="services-card-image-wrapper">
                <Img fluid={nastan.node.sliki[1].fluid} alt="terasa" />
              </div>
            </article>
          )
        })}
      </section>
    </>
  )
}

export default styled(ServicesPage)`
  .services-card {
    border: 1px solid var(--maincolor);
    border-top: 2px solid var(--maincolor);
  }

  .services-card-image-wrapper {
    height: calc(50vh - var(--navbarheight) / 2);
    min-height: 400px;
    margin: 0;
  }

  .gatsby-image-wrapper {
    background-attachment: fixed;
    background-size: cover;
    background-position: 60% 50%;
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    min-height: 400px;
    padding: 0;
    margin: 0;
  }

  @media (min-width: 800px) {
    .services-card {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &:nth-child(even) {
        direction: rtl;
      }
    }
  }
`

export const query = graphql`
  query {
    nastani: allContentfulNastani(sort: { order: ASC, fields: createdAt }) {
      edges {
        node {
          icon
          title
          text {
            text
          }
          sliki {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    allContentfulPageSettings {
      nodes {
        pageServicesImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
